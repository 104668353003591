import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61080cf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "podcast_view" }
const _hoisted_2 = { class: "podcast_row" }
const _hoisted_3 = { class: "podcast_column" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "podcast_column second" }
const _hoisted_6 = { class: "white-text-content" }
const _hoisted_7 = { class: "podcast_name" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.state.podcast.podcastImage,
          style: {"max-width":"100%","height":"auto","object-fit":"cover"}
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.state.podcast.name), 1),
          _createElementVNode("div", {
            innerHTML: _ctx.state.podcast.mix
          }, null, 8, _hoisted_8),
          _createTextVNode(_toDisplayString(_ctx.state.podcast.interview), 1)
        ])
      ])
    ])
  ]))
}